import { graphql } from "gatsby";
import React, { useState } from "react";
import Footer from "../components/footer";
import Navigation from "../components/navigation";
import Title from "../components/title";
import { VoucherCategories } from "../components/VoucherCategories";
import Modal from "../components/modal";
import { getZipCode } from "../utils";
import useCart from "../context/cart.context";

const BrunchSchenken = ({ data }) => {
  const imageSrc = data.wcProductsCategories.acf.hero_image;
  const [openModal, setOpenModal] = useState<boolean>(false);
  const ref = React.createRef<HTMLButtonElement>();
  const { zipCode } = useCart()

  return (
    <>
      <Navigation noHero={false} />
      <div
        className="mt-12"
        onClick={() =>
          typeof window !== undefined && !getZipCode && !zipCode
            ? setOpenModal(true)
            : setOpenModal(false)
        }
      >
        <VoucherCategories data={data.wpProductCategory} contentData={{}}>
          <Title
            title={data.wcProductsCategories.acf.schenken_title}
            breadcrumb="schenken"
            subtitle={data.wcProductsCategories.acf.schenken_description}
          />
        </VoucherCategories>
      </div>
      <div className="px-8 py-12 mx-auto max-w-7xl">
        <img src={imageSrc} className="w-full h-auto" alt="" />
      </div>
      <Footer />
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        pCancelButtonRef={ref}
      />
    </>
  );
};

export const query = graphql`
  query BrunchSchenken {
    wcProductsCategories(slug: { eq: "vouchers" }) {
      acf {
        hero_image_localFile {
          url
        }
        schenken_title
        schenken_description
      }
    }
    wpProductCategory(slug: { eq: "vouchers" }) {
      id
      name
      slug
      description
      products {
        nodes {
          ... on WpBundleProduct {
            id
            name
            slug
            description
            price
            image {
              id
              sourceUrl
            }
            metaData {
              key
              value
            }
            attributes {
              nodes {
                name
                options
              }
            }
          }
        }
      }
    }
  }
`;

export default BrunchSchenken;
